import gql from 'graphql-tag'

const UPSERT_PROFILE = gql`mutation insertProfile(
    $profile: form_builder_hasura_organization_insert_input!
) {
    profile: insert_form_builder_hasura_organization(
        objects: [$profile],
        on_conflict: {
            constraint: organization_pkey,
            update_columns: [abn, branch_or_main, name, number_of_members, structure, type, website, address_id, phone_id]
        }
    ) {
        returning {
            tenant {
                tenant_id
                name
                short_name
            }
            organization_id
            name
            type
            branch_or_main
            number_of_members
            structure
            address_id
            addresses {
                address_id
                unit
                address_line1
                address_line2
                suburb
                postcode
                state
            }
            phone_id
            phonees {
                phone_id
                code
                phone
                tenant_id
            }
        }
    }
}
`

export {
    UPSERT_PROFILE
}
