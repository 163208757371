<template lang="pug">
    div 
        .max-w-960.m-auto.mt-6.px-8
            h1.text-center My Profile
            h3.mt-4.mb-2 Organisation
            Details(
                ref="practiceDetails"
                v-model="profileData"
                @update-name="updateName"
                @update-short-name="updateShortName"
            )
            .grid.grid-flow-col.grid-cols-3.gap-4
                .col-span-2
                    h3.mt-4.mb-2 Practice Logo
                    .text-sm Upload the practice logo so that it appears on the top of the survey. If this field is empty, the Quality Practice Accreditation logo will display instead. 
                    .text-sm.mb-2.font-bold File must be PNG or JPG format & minimum height 200px.
                    v-file-input(:rules="rules"
                    accept="image/png, image/jpeg"
                    filled dense
                    label="Logo Image File")
                    
                .col-span-1.mt-8
                    v-img(
                    src=""
                    id="Preview_image_create"
                    class="w-full h-auto Preview_image")

            h3.mt-4.mb-2 Contact
            .grid.grid-flow-col.grid-cols-2.gap-4
                Address(ref="contactDetails" v-model="address")
                PhoneNumber(
                    v-model="profileData.phone"
                    @update-phone="updatePhone"
                )
            .mt-4.flex.justify-center.flex-col
                v-alert(
                    v-if="showAlert"
                    :type="alertType"
                    class="text-sm mt-5"
                ) {{ alertMessage }}
                v-btn(
                    class="m-auto w-40"
                    depressed
                    color="success"
                    raised
                    @click.stop="updateProfile()"
                )
                    | {{ btnText }}
</template>

<script>
import { computed, ref } from "@vue/composition-api"
import { GET_PROFILE_BY_TENANT_ID } from "@/graphql/queries/getProfileByTenantId.gql"
import { GET_TENANT_BY_SHORT_NAME } from "@/graphql/queries/getTenantByShortName.gql"
import { UPSERT_PROFILE } from "@/graphql/mutations/updateProfile.gql"
import { useQuery, useLazyQuery, useMutation } from "@vue/apollo-composable"
import camelcaseKeys from "camelcase-keys"
import snakecaseKeys from "snakecase-keys"
import Details from "@/components/profile-settings/Details"
import Address from "@/components/shared/Address"
import PhoneNumber from "@/components/shared/PhoneNumber"
import { createNamespacedHelpers } from "vuex-composition-helpers"
import { required } from "@/modules/formRules"
import { logErrorMessages } from "@vue/apollo-util"

const { useState } = createNamespacedHelpers("app")

export default {
    name: "ProfileSettings",
    components: { Details, Address, PhoneNumber },
    setup(_, { root }) {
        const isValid = ref(false)
        const readOnly = ref(false)
        const rules = ref([])
        const profileVariables = ref({})
        const route = root.$route
        const query = route.query
        const showAlert = ref(false)
        const alertMessage = ref("")
        const alertType = ref("")
        const practiceDetails = ref({})
        // console.log("tenant_id", query.t)
        let tenantId = query.t
        if (!tenantId) tenantId = useState(["tenantId"])?.tenantId
        const defaultPhone = {
            code: "",
            phone: "",
            tenantId: tenantId,
        }
        const defaultProfile = {
            name: "",
            type: "",
            branchOrMain: "",
            numberOfMembers: null,
            structure: "",
            tenantId: tenantId,
            phone: { ...defaultPhone },
        }
        const profileData = ref({ ...defaultProfile })
        const address = ref({
            unit: "",
            addressLine1: "",
            addressLine2: "",
            suburb: "",
            postcode: "",
            state: "",
            tenantId: tenantId,
        })
        const params = computed(() => {
            return {
                shortName: profileData.value?.tenant?.shortName,
            }
        })

        const loading = ref(true)

        const { onResult, onError } = useQuery(GET_PROFILE_BY_TENANT_ID, {
            tenantId,
        })

        const btnText = computed(() => {
            return savingProfile.value ? "Saving..." : "Save"
        })

        onResult(({ data: { profile } }) => {
            const profileResponse = camelcaseKeys(profile[0], {
                deep: true,
            }) || { ...defaultProfile }

            const loadedAddress = {
                ...address.value,
                ...(profileResponse?.address ?? {}),
            }
            if (loadedAddress?.typename) delete loadedAddress.typename
            if (profileResponse) {
                delete profileResponse.typename
                delete profileResponse.address
            }
            address.value = { ...loadedAddress }
            if (!profileResponse?.phone)
                profileResponse.phone = { ...defaultPhone }
            profileData.value = { ...(profileResponse || defaultProfile) }

            loading.value = false
        })
        onError((error) => {
            console.log("error that happened")
            logErrorMessages(error)
        })

        const {
            error: error,
            onError: mutationError,
            mutate: addOrUpdateProfile,
            loading: savingProfile,
        } = useMutation(UPSERT_PROFILE, () => ({
            variables: {
                profile: profileVariables.value,
            },
            options: {
                refetchQueries: [GET_PROFILE_BY_TENANT_ID],
            },
            update: (store, { data: { profile } }) => {
                if (!profile?.returning.length) return
                const profileResponse = camelcaseKeys(profile?.returning[0], {
                    deep: true,
                }) || { ...defaultProfile }
                const loadedAddress = {
                    ...address.value,
                    ...(profileResponse?.address ?? {}),
                }

                if (loadedAddress?.typename) delete loadedAddress.typename
                if (profileResponse) {
                    delete profileResponse.typename
                    delete profileResponse.address
                }
                address.value = { ...loadedAddress }
                if (!profileResponse?.phone)
                    profileResponse.phone = { ...defaultPhone }
                profileData.value = { ...(profileResponse || defaultProfile) }

                // now we update the cache
                const data = store.readQuery({
                    query: GET_PROFILE_BY_TENANT_ID,
                    variables: {
                        tenantId,
                    },
                })
                data.profile[0] = { ...profile?.returning[0] }
                store.writeQuery({
                    query: GET_PROFILE_BY_TENANT_ID,
                    variables: {
                        tenantId,
                    },
                    data,
                })

                showAlert.value = true
                alertMessage.value = "Updated profile"
                alertType.value = "success"
            },
        }))

        mutationError((error) => {
            showAlert.value = true
            alertMessage.value = error.message
            alertType.value = "error"
        })

        const {
            onResult: getTenantOnResult,
            onError: getTenantOnError,
            refetch: refetchTenant,
            load: loadTenant,
        } = useLazyQuery(GET_TENANT_BY_SHORT_NAME, params)
        getTenantOnResult(({ data }) => {
            const tenantByShortName = camelcaseKeys(data?.tenant, {
                deep: true,
            })
            const currentTenantId = profileData.value?.tenant?.tenantId

            showAlert.value = false
            isValid.value = true
            if (tenantByShortName?.length) {
                if (tenantByShortName[0].tenantId === currentTenantId) return
                isValid.value = false
                showAlert.value = true
                alertMessage.value =
                    "This short name is used by another user. Please input another name."
                alertType.value = "error"
            }
        })

        const updateName = (name) => {
            profileData.value.tenant.name = name
        }
        const updateShortName = (name) => {
            profileData.value.tenant.shortName = name
            showAlert.value = false
            practiceDetails.value.validate()
            refetchTenant()
        }
        const updatePhone = (phone) => {
            profileData.value.phone.phone = phone
        }

        const validate = () => {
            return isValid.value && practiceDetails.value.validate()
        }

        const updateProfile = async () => {
            if (!validate()) return

            showAlert.value = false
            alertMessage.value = ""
            let { addressId, phoneId, ...newProfile } = {
                ...profileData.value,
            }

            newProfile = snakecaseKeys(newProfile, { exclude: ["__typename"] })
            const newAddress = { ...address.value } //snakecaseKeys(address.value, {deep: true, exclude: ['__typename']})

            newProfile.address = {
                data: {
                    ...snakecaseKeys(newAddress, {
                        deep: true,
                        exclude: ["__typename"],
                    }),
                },
                on_conflict: {
                    constraint: "address_pkey",
                    update_columns: [
                        "unit",
                        "address_line1",
                        "address_line2",
                        "postcode",
                        "state",
                        "suburb",
                    ],
                },
            }
            if (newProfile?.tenant?.typename)
                delete newProfile?.tenant?.typename
            newProfile.tenant = {
                data: {
                    ...snakecaseKeys(newProfile.tenant, {
                        deep: true,
                        exclude: ["__typename"],
                    }),
                },
                on_conflict: {
                    constraint: "tenant_pkey",
                    update_columns: ["name", "short_name"],
                },
            }
            if (newProfile?.phone?.typename) delete newProfile?.phone?.typename
            newProfile.phone = {
                data: {
                    ...snakecaseKeys(newProfile.phone, {
                        deep: true,
                        exclude: ["__typename"],
                    }),
                },
                on_conflict: {
                    constraint: "phone_pkey",
                    update_columns: ["code", "phone"],
                },
            }

            if (!newProfile.tenant.data.short_name) {
                let name = newProfile?.tenant?.data?.name

                if (name) {
                    name = name
                        .replace(/[^\w\s]/gi, "")
                        .replace(" ", "")
                        .toLowerCase()
                    if (name.length > 15)
                        newProfile.tenant.data.short_name = name.substring(
                            0,
                            15
                        )
                    else newProfile.tenant.data.short_Name = name
                }
            }

            profileVariables.value = { ...newProfile }
            await addOrUpdateProfile()
        }

        loadTenant()

        return {
            practiceDetails,
            readOnly,
            rules,
            profileData,
            address,
            loading,
            error,
            savingProfile,
            btnText,
            showAlert,
            alertMessage,
            alertType,
            updateName,
            updateShortName,
            updatePhone,
            updateProfile,
            required,
        }
    },
}
</script>
