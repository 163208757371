import gql from 'graphql-tag'

const GET_PROFILE_BY_TENANT_ID = gql`query getProfileByTenantId($tenantId: uuid!) {
    profile: form_builder_hasura_organization(
        where: {tenant_id: {_eq: $tenantId}}
    ) {
        tenant {
            tenant_id
            name
            short_name
        }
        organization_id
        name
        type
        branch_or_main
        number_of_members
        structure
        address_id
        email
        website
        addresses {
            address_id
            unit
            address_line1
            address_line2
            suburb
            postcode
            state
        }
        phone_id
        phones {
            phone_id
            code
            phone
            tenant_id
        }
    }
}
`

export {
    GET_PROFILE_BY_TENANT_ID
}
